import { Form } from './Form';
import { Collection } from '@/models/internal';
import { FormBaseSchema } from '@/lib/types';

/**
 * Form structure representing the Collection model
 */
export class CollectionForm extends Form<Collection> {
  public schema: FormBaseSchema = {
    name: {
      label: 'Name',
      type: 'text',
      prependIcon: 'mdi-label-outline',
      rules: [
        Form.rules.required(),
        Form.rules.minLength(1),
        Form.rules.maxLength(128),
      ],
    },
    code: {
      label: 'Code',
      type: 'text',
      prependIcon: 'mdi-barcode',
      rules: [
        Form.rules.required(),
        Form.rules.snakeCase(),
        Form.rules.minLength(1),
        Form.rules.maxLength(255),
      ],
    },
    meta: {
      label: 'Metadata',
      type: 'group',
      schema: {
        description: {
          label: 'Description',
          type: 'text',
          prependIcon: 'mdi-text-box-outline',
          rules: [
            Form.rules.maxLength(100),
          ],
          flex: 12,
        },
      },
    },
  };

  public getDefault(field: string) {
    return field;
  }
}
