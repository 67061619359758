






































import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoListSection from '@/components/InfoList/InfoListSection.vue';
import { Collection } from '@/models/internal';
import { IModelView } from '@/lib/interfaces';
import { CollectionService, UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import { InfoListSection as InfoListSectionType } from '@/lib/types';
import CustomersDataTable from '@/views/Dashboard/Customers/components/CustomersDataTable.vue';
import ProductsDataTable from '@/views/Dashboard/Products/components/ProductsDataTable/ProductsDataTable.vue';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import CollectionsProductsList from '@/views/Dashboard/Collections/CollectionsView/components/CollectionsProductsList.vue';
import CollectionsCustomersList from '@/views/Dashboard/Collections/CollectionsView/components/CollectionsCustomersList.vue';
import { Sync } from 'vuex-pathify';
import { ContextBarAction } from '@/components/ContextBar/types/ContextBarAction.type';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';
import { ContextBarManager } from '@/components/ContextBar/classes/ContextBarManager';

@Component({
  name: 'CollectionsView',
  components: {
    InfoListSection,
    CustomersDataTable,
    TableRowActions,
    ProductsDataTable,
    CollectionsProductsList,
    CollectionsCustomersList,
  },
})
export default class CollectionsView extends Vue implements IModelView<Collection>, IUpdateContextBar {
  @Prop({ required: true })
  public readonly id!: string | number;

  @Sync('ui/contextBar@actions')
  protected contextBarActions!: ContextBarAction[];

  public data: Collection | null = null;

  public loading = false;

  public blacklistCollectionAlertText = '';

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CollectionsView' });

  /**
   * All the columns in the Collection details card to be iterated
   * on by section
   */
  protected get sections(): InfoListSectionType[] {
    if (!this.data) return [];
    return [
      {
        label: 'Collection Information',
        items: [
          {
            label: 'Name',
            value: this.data.name,
            icon: 'mdi-label-outline',
            divider: false,
          },
          {
            label: 'Code',
            value: this.data.code,
            icon: 'mdi-barcode',
            divider: false,
          },
        ],
      },
      {
        label: 'Metadata',
        items: [
          {
            label: 'Description',
            value: this.data.meta.description,
            icon: 'mdi-card-text-outline',
          },
        ],
      },
    ];
  }

  public created() {
    this.init();
    this.updateContextBar();
  }

  /**
   * Update the ContextBar.
   */
  public updateContextBar() {
    if (!this.data || !this.canUpdate(this.data)) return;

    ContextBarManager.addActions({
      color: 'primary',
      icon: 'mdi-pencil-outline',
      label: 'Edit Collection',
      to: { name: 'collections-edit', params: { id: String(this.id) } },
    });

    ContextBarManager.addActions({
      color: 'error',
      callback: this.destroyCollection,
      icon: 'mdi-delete-outline',
      label: 'Delete Collection',
    });
  }

  public async fetchData(id: string | number): Promise<Collection> {
    const collection = await this.collectionService.api.findOne({
      id,
      authentication_token: this.userService.getActiveToken(),
    });

    if (!collection) throw Error('Unable to fetch Collection record from server');

    return new Collection(collection);
  }

  public async init(): Promise<void> {
    this.loading = true;
    try {
      const collection = await this.fetchData(this.id);
      if (collection) this.data = collection;

      if (collection.blacklist_parent_collection) {
        this.blacklistCollectionAlertText = `This is a blacklist for Collection ${collection.blacklist_parent_collection.name} `;
      }
    } catch (error) {
      this.logger.error(error);
    }

    this.loading = false;
  }

  /**
   * User can update a Customer record
   */
  protected canUpdate(collection: Collection) {
    return this.$ability.can('update', collection);
  }

  /**
   * Can read a Customer record
   */
  protected canRead() {
    return this.$ability.can('read', Collection);
  }

  /**
   * Can read a Customer record
   */
  protected canManage() {
    return this.$ability.can('manage', Collection);
  }

  protected async destroyCollection() {
    try {
      await this.collectionService.api.destroy({
        authentication_token: this.userService.getActiveToken(),
        id: this.id,
      });

      this.$genify.notify(`Collection ${this.data?.name} removed!`, 'success');
      this.$router.push({
        name: 'collections-list',
      });
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(`There was an error removing Collection ${this.data?.name}.`, 'error');
    }
  }
}
