var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({ref:"table",staticClass:"products-data-table",attrs:{"headers":_vm.headers,"items-per-page":_vm.pageCount,"items":_vm.products,"page":_vm.pageNumber,"server-items-length":_vm.totalRecords,"footer-props":{
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 10, 25, 50],
  },"no-data-text":"No Products found","show-select":_vm.showSelect,"disable-sort":""},on:{"update:itemsPerPage":function($event){_vm.pageCount=$event},"update:items-per-page":function($event){_vm.pageCount=$event},"update:page":function($event){_vm.pageNumber=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("table-actions"),_c('list-select-all-alert',{attrs:{"records-text":"products","selected-records":_vm.selectedProducts.length,"total-records":_vm.totalRecords,"every-item-toggle":_vm.everyItemToggle,"all-items-selected":_vm.syncedAllItemsSelected},on:{"update:allItemsSelected":function($event){_vm.syncedAllItemsSelected=$event},"update:all-items-selected":function($event){_vm.syncedAllItemsSelected=$event}}})]},proxy:true},{key:"header",fn:function(ref){
  var everyItem = ref.props.everyItem;
return [_vm._v(" "+_vm._s(_vm.setEveryItemToggle(everyItem))+" ")]}},{key:"item.image_url",fn:function(ref){
  var product = ref.item;
return [_c('products-image-thumb',{staticClass:"products-data-table__image my-2",attrs:{"product":product,"use-upload":false,"use-lightbox":true}})]}},{key:"item.title",fn:function(ref){
  var product = ref.item;
return [_c('p',{staticClass:"products-data-table__title"},[_vm._v(" "+_vm._s(product.title)+" ")])]}},{key:"item.collections",fn:function(ref){
  var product = ref.item;
return _vm._l((product.collections),function(collection){return _c('v-chip',{key:collection.name,staticClass:"products-data-table__collections",attrs:{"color":_vm.stateColor(collection),"label":"","outlined":""}},[_vm._v(" "+_vm._s(collection.name)+" ")])})}},{key:"item.inventory",fn:function(ref){
  var product = ref.item;
return [(product.inventory.quantity && product.inventory.quantity > 0)?_c('span',{staticClass:"font-weight-bold green--text"},[_vm._v(" "+_vm._s(product.inventory.quantity)+" ")]):_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v(" 0 ")])]}},{key:"item.auto_approval_limit",fn:function(ref){
  var product = ref.item;
return [(product.meta.auto_approval_quantity_limit && product.meta.auto_approval_quantity_limit > 0)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(product.meta.auto_approval_quantity_limit)+" ")]):_c('span',{staticClass:"font-weight-bold grey--text text--lighten-2"},[_vm._v(" N/A ")])]}},{key:"item.custom_auto_approval_limit",fn:function(ref){
  var product = ref.item;
return [_vm._t("custom_auto_approval_limit",null,{"product":product})]}},{key:"item.created_at",fn:function(ref){
  var product = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(product.created_at, true)))])]}},{key:"item.updated_at",fn:function(ref){
  var product = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(product.updated_at, true)))])]}},{key:"item.alerts",fn:function(ref){
  var product = ref.item;
return _vm._l((_vm.getAlerts(product)),function(alert){return _c('products-alert',{key:alert.label,attrs:{"label":alert.label,"description":alert.description,"color":alert.color,"icon":alert.icon}})})}},{key:"item.actions",fn:function(ref){
  var product = ref.item;
return [_vm._t("row-actions",null,{"record":product})]}}],null,true),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }