


































import { Vue, Component, Prop } from 'vue-property-decorator';
import { InfoListSection as InfoListSectionType } from '@/lib/types/InfoList.type';
import InfoList from '@/components/InfoList/InfoList.vue';

@Component({
  name: 'InfoListSection',
  components: {
    InfoList,
  },
})
export class InfoListSection extends Vue {
  @Prop({ required: true, default: () => ({}) })
  protected readonly sections!: InfoListSectionType[];

  @Prop({ required: false, default: true })
  protected readonly columns!: boolean;
}

export default InfoListSection;
