import BaseModel from '../BaseModel';
import { FormBaseSchema } from '@/lib/types/FormBaseSchema.type';
import _ from 'lodash';

export abstract class Form<T extends BaseModel> {
  /**
   * Instance of the model
   */
  public model: T;

  /**
   * Schema used against Model for use within FormBase component
   */
  public abstract schema: FormBaseSchema;

  /**
   * Constructor
   */
  public constructor(model: T) {
    this.model = _.cloneDeep(model);
  }

  /**
   * Function responsible for fetching default values for
   * a given field
   */
  public getSchema(): FormBaseSchema {
    return this.schema;
  }

  /**
   * Get the form's current record it's using as a model.
   */
  public getModel(): T {
    return this.model;
  }

  /**
   * Function responsible for fetching default values for
   * a given field
   */
  public setModel(model: T): T {
    this.model = _.cloneDeep(model);
    return this.model;
  }

  /**
   * When initialized, the model will be hydrated with default values
   * provided when passed a field name
   * @param field
   */
  protected abstract getDefault(field: string): string | number;

  /**
   * Basic form rules
   */
  public static get rules() {
    return {
      /**
       * Minimum length of a form field's input
       */
      minLength: (length: number) => (v: string | Array<any>) => (
        (!v || v.length >= length) || `Minimum length for this field is ${length}`
      ),

      /**
       * Maximum length of a form field's input
       */
      maxLength: (length: number) => (v: string | Array<any>) => (
        (!v || v.length <= length) || `Maximum length for this field is ${length}`
      ),

      /**
       * Check for required field and display message if not valid
       */
      required: (message = 'Required field.') => (v: any) => !!v || message,

      /**
       * Check for valid email string and display message if not
       */
      validEmail: (message = 'Invalid email.') => (v: string) => (!v || /.+@.+\..+/.test(v)) || message,

      /**
       * Ensure value is in a "snake_case" format
       */
      snakeCase: (message = 'Must be "snake_case" - lowercase, and use underscores instead of spaces') => (
        (v: string) => (!v || /^([a-z]{1,})(_[a-z0-9]{1,})*$/.test(v)) || message
      ),

      /**
       * Ensure value is numeric
       */
      numeric: (message = 'Must be a number - no letters or special charaters') => (
        (v: string) => (!v || /^[0-9]*$/.test(v)) || message
      ),
    };
  }
}
