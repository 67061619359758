














































import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoListItem from '@/components/InfoList/InfoListItem.vue';
import type { InfoList as InfoListType } from '@/lib/types/InfoList.type';

@Component({
  name: 'InfoList',
  components: {
    InfoListItem,
  },
})
export default class InfoList extends Vue {
  @Prop({ required: true, default: () => ([]) })
  private readonly items!: InfoListType;

  @Prop({ required: false, default: '' })
  private readonly label!: string;
}
