






























































import { Component, Vue, Emit, Prop, Ref } from 'vue-property-decorator';
import ImageUpload from 'vue-image-upload-resize';
import { IUploaderView } from '@/lib/interfaces/UploaderView.interface';
import { Asset } from '@/models/internal';
import { UploaderProps, UploaderResponse } from './components/UploaderDialog.vue';

export type ImageUploaderProps = UploaderProps & {
  /**
   * Extraneous props
   */
  options: {
    /**
     * Image URL to show if rendering uploader to replace an existing image
     */
    asset?: Asset | null;
    /**
     * Array of accepted MIME types for the file
     */
    accept?: string[];
  };
};

export type ImageUploaderResponse = UploaderResponse & {
  /**
   * Image uploader will return a File object in the data field.
   */
  data: File;
};

@Component({
  name: 'ImageUploader',
  components: {
    ImageUpload,
  },
})
export class ImageUploader extends Vue implements IUploaderView {
  @Prop({ required: false, default: () => ({}) })
  public options!: Record<string, any>;

  @Ref('uploader')
  private readonly uploaderRef!: any;

  protected debug = process.env.VUE_APP_ENV !== 'production' ? 1 : 0;

  protected hasImage = false;

  protected maxWidth = 1024;

  protected maxHeight = 1024;

  /**
   * Acceptable file-types for the image uploader
   */
  protected get acceptedFormats() {
    return this.options.accept ?? ['image/png', 'image/jpeg', 'image/gif'];
  }

  protected get asset(): Asset | null {
    return this.options.asset ?? null;
  }

  protected get canvasSize() {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return 128;
    }

    return 500;
  }

  @Emit('file-update')
  public onFileUpdate(file: File) {
    // For some reason, it won't let me return the File object without
    // assigning it to a variable first
    const fileRef = file;
    return fileRef;
  }

  public mounted() {
    this.setPreview();
  }

  /**
   * Set preview image for the uploader. When a file is selected from the client's
   * system as an upload, the image will be visible as a preview.
   */
  protected setPreview() {
    if (!this.asset) return;
    this.uploaderRef.imagePreview = this.asset.largestImage();
    this.hasImage = true;
  }

  /**
   * Click handler for image upload button
   */
  protected onFileInputClick(event: Event) {
    event.preventDefault();
    const input = this.uploaderRef.$el.children[1] as HTMLInputElement;
    input.click();
  }
}

export default ImageUploader;
