






























import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component({
  name: 'ListSelectAllAlert',
})
export default class ListSelectAllAlert extends Vue {
  @Prop({ required: true })
  protected readonly recordsText!: string;

  @Prop({ required: true })
  protected readonly selectedRecords!: number;

  @Prop({ required: true })
  protected readonly totalRecords!: number;

  @Prop({ required: true })
  protected readonly everyItemToggle!: boolean;

  @PropSync('allItemsSelected', { required: true })
  protected syncedAllItemsSelected!: boolean;
}
