


































































































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { Product, Collection } from '@/models/internal';
import { DataTableHeader } from 'vuetify';
import { Utility } from '@/tools/Utility';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import ProductsAlert, { IProductsAlert } from './components/ProductsAlert.vue';
import ListSelectAllAlert from '@/components/Alert/ListSelectAllAlert.vue';
import { ProductsImageThumb } from '@/components/Products/ProductsImageThumb.vue';

const defaultHeaders: DataTableHeader[] = [
  { text: 'Thumbnail', value: 'image_url', sortable: false, filterable: false },
  { text: 'SKU', value: 'sku', sortable: false, filterable: false },
  { text: 'Title', value: 'title', sortable: false, filterable: false },
  { text: 'Collections', value: 'collections', width: 120, sortable: false, filterable: false },
  { text: 'Inventory', value: 'inventory', width: 120, sortable: false, filterable: false },
  { text: 'Alerts', value: 'alerts', width: 120, sortable: false, filterable: false },
  { text: 'Actions', value: 'actions', width: 120, sortable: false, filterable: false },
];

@Component({
  name: 'ProductsDataTable',
  components: {
    TableRowActions,
    ApprovalStatusIndicator,
    ProductsAlert,
    ListSelectAllAlert,
    ProductsImageThumb,
  },
})
export default class ProductsDataTable extends Vue {
  @Prop({ required: false, default: () => defaultHeaders })
  protected readonly headers!: DataTableHeader[];

  @Prop({ required: false, default: () => [] })
  protected readonly products!: Product[];

  @Prop({ required: true })
  protected totalRecords!: number | string;

  @Prop({ required: false, default: true })
  protected showSelect!: boolean;

  @PropSync('selected', { required: false, default: () => [] })
  protected readonly selectedProducts!: Product[];

  @PropSync('page', { required: true })
  protected pageNumber!: number | string;

  @PropSync('perPage', { required: true })
  protected pageCount!: number | string;

  @PropSync('allItemsSelected', { required: false, default: false })
  protected syncedAllItemsSelected!: boolean;

  protected formatDate = Utility.formatDate;

  protected everyItemToggle = false;

  protected stateColor(collection: Collection) {
    if (collection.blacklist_parent_collection_id) {
      return 'black';
    } else {
      return 'primary';
    }
  }

  /**
   * Workaround to access v-data-header every-item prop
   * (Could not find a way to sync header-props)
   */
  protected setEveryItemToggle(everyItem: boolean) {
    this.everyItemToggle = everyItem;
    if (!everyItem) {
      this.syncedAllItemsSelected = false;
    }
  }

  /**
   * Show information pertaining to the Product's status in relation to
   * the Order form (whether it's being shown, etc.)
   */
  protected getAlerts(product: Product): Record<string, any> {
    const alerts: IProductsAlert[] = [];

    if (!product.meta.valid) {
      alerts.push({
        label: 'Invalid Meta',
        description: 'Requires an update to it\'s metadata to show on form',
        color: 'error',
        icon: 'mdi-alert-octagon',
      });
    }

    if (product.inventory.stop_selling) {
      alerts.push({
        label: 'Stop Selling',
        description: 'Flagged for discontinued selling - will not show on form',
        color: 'warning',
        icon: 'mdi-alert',
      });
    }

    return alerts;
  }
}
