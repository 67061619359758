var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-actions"},[(_vm.view && _vm.canRead(_vm.record))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.uiLoading,"color":"accent","text":"","fab":"","small":""},on:{"click":function($event){return _vm.view(_vm.record)}}},on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,false,1545930965)},[_c('span',[_vm._v("View")])])]:_vm._e(),(_vm.edit && _vm.canUpdate(_vm.record))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.uiLoading,"color":"primary","text":"","fab":"","small":""},on:{"click":function($event){return _vm.edit(_vm.record)}}},on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,1127514898)},[_c('span',[_vm._v("Edit")])])]:_vm._e(),(_vm.destroy && _vm.canDestroy(_vm.record))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.uiLoading,"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.destroy(_vm.record)}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,false,3408546876)},[_c('span',[_vm._v("Delete")])])]:_vm._e(),(_vm.approve && _vm.canUpdateStatus(_vm.record))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.uiLoading,"color":"success","text":"","fab":"","small":""},on:{"click":function($event){return _vm.approve(_vm.record)}}},on),[_c('v-icon',[_vm._v("mdi-clipboard-check-outline")])],1)]}}],null,false,2099454900)},[_c('span',[_vm._v("Approve")])])]:_vm._e(),(_vm.deny && _vm.canUpdateStatus(_vm.record))?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.uiLoading,"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.deny(_vm.record)}}},on),[_c('v-icon',[_vm._v("mdi-clipboard-remove-outline")])],1)]}}],null,false,373088950)},[_c('span',[_vm._v("Deny")])])]:_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }