















































































































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { Customer } from '@/models/internal';
import DistChannelAutocomplete from '@/components/Autocomplete/DistChannelAutocomplete.vue';

@Component({
  name: 'CustomersListControls',
  components: {
    DistChannelAutocomplete,
  },
})
export default class CustomersListControls extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  protected readonly showDistChannelsFilter!: boolean;

  @Prop({ required: false, default: false })
  protected readonly allItemsSelected!: boolean;

  @Prop({ required: false, default: () => [] })
  protected readonly actions!: CustomersListControlsAction[];

  @Prop({ required: false, default: false })
  protected loading!: boolean;

  @PropSync('search')
  protected searchText!: string;

  @PropSync('selected', { required: false, default: () => [] })
  protected syncedSelected!: Customer[];

  @PropSync('distChannels', { required: false, default: () => [] })
  protected syncedDistChannels!: string[];
}

type CustomersListControlsAction = {
  label: string;
  icon: string;
  value: string;
};
