


















import { IEditFormView } from '@/lib/interfaces';
import Collection from '@/models/Collection';
import { CollectionForm } from '@/models/forms/CollectionForm';
import { CollectionService, UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import CollectionsForm from './components/CollectionsForm.vue';

@Component({
  name: 'CollectionsEdit',
  components: {
    CollectionsForm,
  },
})
export default class CollectionsEdit extends Vue implements IEditFormView<Collection> {
  @Prop({ required: true })
  public readonly id!: string | number;

  @Ref('collection-form')
  public readonly formRef: any;

  @Sync('context/overlay@visible')
  protected isOverlayVisible!: boolean;

  public form!: CollectionForm;

  protected loading = false;

  protected loader = 'list-item-avatar, actions';

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CollectionsEdit' });

  protected get schema() {
    return this.form?.getSchema();
  }

  protected get model() {
    return this.form?.getModel();
  }

  protected set model(model) {
    this.form?.setModel(model);
  }

  public created() {
    this.init();
  }

  public async fetchData(id: string|number): Promise<Collection> {
    const collection = await this.collectionService.api.findOne({
      id,
      authentication_token: this.userService.getActiveToken(),
    });

    if (!collection) throw Error('Unable to find Collection on server');

    return new Collection(collection);
  }

  public async init(): Promise<void> {
    this.loading = true;

    try {
      const collection = await this.fetchData(this.id);
      this.form = new CollectionForm(collection);
    } catch (error) {
      this.logger.error(error);
    }

    this.loading = false;
  }

  public async save(model: Collection): Promise<any> {
    this.isOverlayVisible = true;

    try {
      await this.collectionService.api.update({
        id: this.id,
        authentication_token: this.userService.getActiveToken(),
        collection: model,
      });
      this.$router.push({ name: 'collections-list' });
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.isOverlayVisible = false;
    }
  }
}
