









































import { IFindOneArguments, ITableView } from '@/lib/interfaces';
import { Collection, Product } from '@/models/internal';
import { CollectionService, ProductService, UserService } from '@/services';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { debounce } from 'lodash';
import { Logger } from '@/tools/Logger';
import prepareData from '@/models/functions/prepareData';
import ProductsListControls from '@/views/Dashboard/Products/components/ProductsListControls.vue';
import { renderBatchDialog } from '@/components/Dialog/BatchProcessDialog.vue';
import ProductsDataTable from '@/views/Dashboard/Products/components/ProductsDataTable/ProductsDataTable.vue';
import TableRowActions from '@/components/Table/TableRowActions.vue';

@Component({
  name: 'CollectionsProductsList',
  components: {
    TableRowActions,
    ProductsDataTable,
    ProductsListControls,
  },
})
export default class CollectionsProductsList extends Vue implements ITableView<Product> {
  @Prop({ required: true })
  private readonly collection!: Collection;

  public selected: Product[] = [];

  public headers: DataTableHeader[] = [
    { text: 'Image', value: 'image_url', width: 150,  sortable: false, filterable: false },
    { text: 'Sku', value: 'sku', width: 160, sortable: false, filterable: false },
    { text: 'Title', value: 'title', width: 200,  sortable: false, filterable: false },
    { text: 'Auto-Approval Limit', value: 'auto_approval_limit', width: 120 , sortable: false, filterable: false },
    { text: 'Alerts', value: 'alerts', width: 120, sortable: false, filterable: false },
    { text: 'Actions', value: 'actions', width: 160, sortable: false, filterable: false },
  ];

  public data: Product[] = [];

  protected loading = false;

  protected actions = [
    {
      label: 'Remove from Collections',
      value: 'batch_remove_products',
      icon: 'mdi-minus-box-multiple',
    },
  ];

  private readonly productService: ProductService = ProductService.getInstance();

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CollectionsProductsList' });

  private readonly getBatchSelection = renderBatchDialog.bind(this);

  private totalRecords = Infinity;

  private search = '';

  private allItemsSelected = false;

  private readonly pagination = {
    pageNumber: 1,
    pageCount: 5,
  };

  private debouncedInit = debounce(this.init, 500);

  @Watch('pagination', { deep: true })
  protected watchPagination() {
    this.loading = true;
    this.init();
  }

  @Watch('search')
  protected watchSearch() {
    this.loading = true;
    this.selected = [];
    this.pagination.pageNumber = 1;
    this.debouncedInit();
  }

  public created() {
    this.init();
  }

  /**
   * Fetch and process data from API
   */
  public async fetchData() {
    this.loading = true;

    try {
      const params: IFindOneArguments = {
        id: this.collection.id,
        authentication_token: this.userService.getActiveToken(),
        page: this.pagination.pageNumber,
        per_page: this.pagination.pageCount,
      };

      if (this.search) {
        params.sku_containing = this.search;
      }

      // If the pagination has the `All` flag, delete pagination params
      if (this.pagination.pageCount === -1) {
        params.page = 1;
        params.per_page = this.totalRecords;
      }

      const { products, total } = await this.collectionService.api.findProducts(params);

      const preparedData = prepareData(products, ProductService.mapData);
      await this.productService.insert({ data: preparedData });

      return {
        products: this.productService.findIn(products.map((data: any) => data.id)),
        total,
      };
    } catch (error) {
      this.logger.error(error);

      return {
        products: [],
        total: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  /**
   * Initialize
   */
  public async init() {
    this.loading = true;
    try {
      const { products, total } = await this.fetchData();
      this.data = products;
      this.totalRecords = total;
    } catch (error) {
      this.logger.error(error);
    }
    this.loading = false;
  }

  /**
   * Listener for actions committed from the multi-select function on the table
   */
  protected async onAction(action: string) {
    try {
      switch (action) {
        case 'batch_add_products': {
          return this.addMany(this.selected);
        }
        case 'batch_remove_products': {
          return this.removeMany(this.selected);
        }
        default:
          break;
      }
    } catch (error) {
      // Catch cancelled promise via dialog
      this.logger.error(error);
    }
  }

  /**
   * Remove a single Product from this Collection
   */
  protected async removeOne(product: Product) {
    this.loading = true;

    try {
      await this.collectionService.api.removeProduct({
        id: this.collection.id,
        product_id: product.id,
        authentication_token: this.userService.getActiveToken(),
      });

      this.selected = this.selected.filter((o) => o.id !== product.id);
      this.init();
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * Remove one or more Products to this Collection
   */
  protected async removeMany(products: Product[]) {
    const { sources, targets } = await this.getBatchSelection<Product, Collection>({
      title: 'Remove Products from Collections',
      subtitle: 'Select one or more Collections to remove your selected Products from',
      sources: products,
      targets: [this.collection],
      sourceType: 'Products',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });

    this.loading = true;

    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchRemoveProducts(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            sku_containing: this.search,
          },
        );
      } else {
        result = await this.collectionService.batchRemoveProducts(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      this.init();
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  /**
   * Add one or more Products to this Collection
   */
  protected async addMany(products: Product[]) {
    const { sources, targets } = await this.getBatchSelection<Product, Collection>({
      sources: products,
      targets: [this.collection],
      title: 'Add Products to Collections',
      subtitle: 'Select one or more Collections to add your selected Products to',
      sourceType: 'Products',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });

    this.loading = true;

    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchAddProducts(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            sku_containing: this.search,
          },
        );
      } else {
        result = await this.collectionService.batchAddProducts(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      this.init();
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  /**
   * User can read a Collection and Product record
   */
  protected canRead() {
    return this.$ability.can('read', Collection) && this.$ability.can('read', Product);
  }

  /**
   * User can edit a Collection and Product record
   */
  protected canUpdateProduct(product: Product) {
    return this.$ability.can('update', product);
  }

  /**
   * User can manage a Collection and Product record
   */
  protected canManage() {
    return this.$ability.can('manage', Collection) && this.$ability.can('manage', Product);
  }
}
