























import { Product } from '@/models/internal';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VAlert } from 'vuetify/lib';

export interface IProductsAlert {
  label: string;
  description: string;
  color: 'success'|'info'|'warning'|'error' ;
  icon?: string;
}

@Component({
  name: 'ProductsAlert',
})
export default class ProductsAlert extends Vue {
  @Prop({ required: true })
  protected readonly label!: IProductsAlert['label'];

  @Prop({ required: true })
  protected readonly description!: IProductsAlert['description'];

  @Prop({ required: false, default: 'info' })
  protected readonly color!: IProductsAlert['color'];

  @Prop({ required: false, default: 'mdi-information' })
  protected readonly icon!: IProductsAlert['icon'];
}

/**
 * Show information pertaining to the Product's status in relation to
 * the Order form (whether it's being shown, etc.)
 */
export function getAlerts(product: Product): IProductsAlert[] {
  const alerts: IProductsAlert[] = [];

  if (!product.meta.valid) {
    alerts.push({
      label: 'Invalid Meta',
      description: 'Requires an update to it\'s metadata to show on form',
      color: 'error',
      icon: 'mdi-alert-octagon',
    });
  }

  if (product.inventory.stop_selling) {
    alerts.push({
      label: 'Stop Selling',
      description: 'Flagged for discontinued selling - will not show on form',
      color: 'warning',
      icon: 'mdi-alert',
    });
  }

  return alerts;
}

/**
 * Helper function for rendering a ProductsAlert
 */
export function showAlertIcon(
  this: any,
  el: Element,
  propsData: IProductsAlert,
): void {
  const instance = new ProductsAlert({
    parent: this,
    propsData,
  });
  instance.$mount();
  el.appendChild(instance.$el);
}

/**
 * Helper function for rendering a VAlert
 */
export function showAlert(
  this: any,
  el: Element,
  propsData: IProductsAlert,
): void {
  const instance = new VAlert({
    parent: this,
    propsData: {
      dense: true,
      text: true,
      type: propsData.color,
      border: 'left',
    },
    render: function(createElement) {
      return createElement('v-alert', { props: propsData }, [
        `${propsData.label} - ${propsData.description}`,
      ]);
    },
  });
  instance.$mount();
  el.appendChild(instance.$el);
}
