






































































































































































































































import { ITableView } from '@/lib/interfaces';
import { Collection } from '@/models/internal';
import { UserService, CollectionService } from '@/services';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { Utility } from '@/tools/Utility';
import { debounce } from 'lodash';
import { Logger } from '@/tools/Logger';

@Component({
  name: 'CollectionsList',
  components: {
    TableRowActions,
  },
})
export default class CollectionsList extends Vue implements ITableView<Collection> {
  @Ref('table')
  private readonly tableRef!: any;

  public debouncedInit = debounce(this.init, 500);

  public data: Collection[] = [];

  public selected: Collection[] = [];

  public headers: DataTableHeader<any>[] = [
    { text: 'Name', value: 'name' },
    { text: 'Code', value: 'code' },
    { text: 'Created', value: 'created_at' },
    { text: 'Updated', value: 'updated_at' },
    { text: 'Actions', value: 'actions', width: 200 },
  ];

  protected formatDate = Utility.formatDate;

  protected totalRecords: number | string = -1;

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CollectionsList' });

  private page: number | string = 1;

  private perPage: number | string = 10;

  private search = '';

  /**
   * Loading state handlers
   */
  private readonly loading = {
    overlay: false,
    table: false,
  };

  protected get expandedItemListWidth() {
    return this.tableRef.$el.offsetWidth * 0.8;
  }

  @Watch('page')
  @Watch('perPage')
  protected watchPagination() {
    this.init();
  }

  @Watch('search')
  protected watchSearch() {
    this.loading.table = true;
    this.page = 1;
    this.debouncedInit();
  }

  public created() {
    this.init();
  }

  public async fetchData() {
    return this.collectionService.api.find({
      name_containing: (this.search?.length > 2) ? this.search : undefined,
      page: this.page,
      per_page: this.perPage,
      authentication_token: this.userService.getActiveToken(),
    });
  }

  public async init(): Promise<void> {
    this.loading.table = true;

    const { collections, total } = await this.fetchData();
    this.totalRecords = total;

    await this.collectionService.create({ data: collections });
    this.data = this.collectionService
      .query()
      .with('blacklist_collections.blacklist_parent_collection')
      .whereIdIn(collections.map((data: any) => data.id))
      .get();

    this.loading.table = false;
  }

  protected async viewCollection(collection: Collection) {
    return this.$router.push({
      name: 'collections-view',
      params: {
        id: String(collection.id),
      },
    });
  }

  protected async editCollection(collection: Collection) {
    return this.$router.push({
      name: 'collections-edit',
      params: {
        id: String(collection.id),
      },
    });
  }

  protected async destroyCollection(collection: Collection) {
    try {
      await this.collectionService.api.destroy({
        authentication_token: this.userService.getActiveToken(),
        id: collection.id,
      });

      this.$genify.notify(`Collection ${collection.name} removed!`, 'success');
      this.init();
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(`There was an error removing Collection ${collection.name}.`, 'error');
    }
  }

  protected canCreate() {
    return this.$ability.can('create', Collection);
  }

  protected canRead(collection: Collection) {
    return this.$ability.can('read', collection);
  }

  protected canUpdate(collection: Collection) {
    return this.$ability.can('update', collection);
  }

  protected canDestroy(collection: Collection) {
    return this.$ability.can('destroy', collection);
  }

  protected createBacklistCollection(collection: Collection) {
    this.$router.push({
      name: 'collections-create',
      query: {
        blacklistParentCollectionId: collection.id.toString(),
      },
    });
  }
}
