

















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { LazyPromise } from '@/lib/LazyPromise';
import { ImageUploader } from '@/components/Uploader/ImageUploader.vue';
import Utility from '@/tools/Utility';
import { IUploaderView } from '@/lib/interfaces/UploaderView.interface';

export type UploaderProps = {
  /**
   * Type of uploader
   */
  type: string;
  /**
   * Options passed on to the uploader as props
   */
  options?: Record<string, any>;
};

export type UploaderResponse = {
  /**
   * Data returned from an uploader response
   */
  data: any;
};

@Component({
  name: 'UploaderDialog',
  components: {
    ImageUploader,
  },
})
export class UploaderDialog extends Vue {
  /**
   * Promise for resolving a value through asynchronously
   */
  @Prop({ required: true, default: undefined })
  protected readonly promise!: LazyPromise<any>;

  /**
   * Confirmation text for dialog-form's affirmative action
   */
  @Prop({ required: false, default: 'Upload' })
  protected readonly confirmText!: string;

  /**
   * Cancellation text for dialog-form's negative action
   */
  @Prop({ required: false, default: 'Cancel' })
  protected readonly cancelText!: string;

  @Prop({ required: true, validator: (type) => ['image'].includes(type) })
  protected readonly type!: string;

  /**
   * Uploader options, specific to it's type
   */
  @Prop({ required: false, default: () => ({}) })
  protected readonly options!: Record<string, any>;

  /**
   * Parse the correct Uploader type from prop
   */
  protected get which() {
    switch (this.type) {
      case 'image':
        return 'ImageUploader';
      default:
        throw Error('Unknown uploader type provided');
    }
  }

  protected get label() {
    return Utility.titleCase(this.type);
  }

  protected get hasImage() {
    return !!this.fileData;
  }

  /**
   * Show dialog modal
   */
  protected dialog = true;

  /**
   * Dialog form data
   */
  protected fileData: File | null = null;

  /**
   * Return a helper function for displaying an UploaderDialog modal.
   */
  public static attach<P = UploaderProps, R = UploaderResponse>(props: P): () => Promise<R> {
    return async function(this: IUploaderView) {
      const lazyPromise = new LazyPromise();
      const instance = new UploaderDialog({
        parent: this,
        propsData: {
          ...props,
          promise: lazyPromise,
          confirmText: 'Upload',
          cancelText: 'Cancel',
        },
      });
      instance.$mount();
      return await lazyPromise;
    };
  }

  protected confirm() {
    this.dialog = false;
    this.promise.resolve({
      data: this.fileData,
    });
    this.$destroy();
  }

  protected cancel() {
    this.dialog = false;
    this.fileData = null;
    this.promise.reject();
    this.$destroy();
  }

  protected onFileUpdate(file: File) {
    this.fileData = file;
  }
}

export default UploaderDialog;
