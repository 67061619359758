
























import { UploaderDialog } from '@/components/Uploader/components/UploaderDialog.vue';
import { Asset } from '@/models/internal';
import Product from '@/models/Product';
import { AssetService, UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ImageUploaderProps, ImageUploaderResponse } from '../Uploader/ImageUploader.vue';

@Component({
  name: 'ProductsImageUploader',
})
export class ProductsImageUploader extends Vue {
  @Prop({ required: true })
  protected readonly product!: Product;

  protected loading = false;

  private readonly assetService = AssetService.getInstance();

  private readonly userService = UserService.getInstance();

  private logger: Logger = new Logger({ context: 'ProductsImageUploader' });

  /**
   * Click handler for uploading an image for a product
   */
  protected async onUploadImageClick(): Promise<void> {
    let image = null;
    try {
      image = await this.showUploader();
    } catch (error) {
      this.logger.info('Cancelled image upload');
    }

    if (!image) return;

    this.loading = true;

    try {
      const data = await this.assetService.api.create({
        authentication_token: this.userService.getActiveToken(),
        entity: 'products',
        entity_id: this.product.id,
        upload: image.data,
        meta: {
          title: `${this.product.title} Image`,
          description: this.product.description,
        },
      });
      const asset = new Asset(data.asset);
      this.product.assets.push(asset);
      this.$emit('image-update', asset);
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('We encountered a problem while uploading your file - sorry! Please try again later.', 'error');
    }

    this.loading = false;
  }

  private getProductImage() {
    return this.product.latestImageAsset();
  }

  /**
   * Display a confirmation dialog modal and allow for input
   * of notes before submitting to server
   */
  private async showUploader() {
    return UploaderDialog.attach<ImageUploaderProps, ImageUploaderResponse>({
      type: 'image',
      options: {
        asset: this.getProductImage() ?? null, // TODO: change key to `preview`
        accept: ['image/png', 'image/jpeg', 'image/gif'],
      },
    }).apply(this);
  }
}

export default ProductsImageUploader;
