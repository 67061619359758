













































































import { Product } from '@/models/internal';
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component({
  name: 'ProductsListControls',
})
export default class ProductsListControls extends Vue {
  @Prop({ required: false, default: false })
  protected readonly allItemsSelected!: boolean;

  @Prop({ required: false, default: () => [] })
  protected readonly actions!: ProductsListControlsAction[];

  @Prop({ required: false, default: false })
  protected loading!: boolean;

  @PropSync('search')
  protected searchText!: string;

  @PropSync('selected', { required: false, default: () => [] })
  protected syncedSelected!: Product[];

  protected dialog = false;
}

type ProductsListControlsAction = {
  label: string;
  icon: string;
  value: string;
};
