





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Customer, User } from '@/models/internal';
import { CustomerService, UserService } from '@/services';
import Logger from '@/tools/Logger';
// import Utility from '@/tools/Utility';

@Component({
  name: 'CustomersListDetails',
})
export default class CustomersListDetails extends Vue {
  @Prop({ required: false, default: '' })
  protected readonly search!: string;

  @Prop({ required: true })
  private readonly customer!: Customer;

  protected data: User[] = [];

  protected loading = false;

  private readonly logger: Logger = new Logger({ context: 'CustomersListDetails' });

  private readonly userService: UserService = UserService.getInstance();

  private readonly customerService: CustomerService = CustomerService.getInstance();

  public created() {
    this.init();
  }

  private async init() {
    this.loading = true;
    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
    }
  }

  private async fetchData() {
    return this.customerService.api.orderFormUsers({
      id: this.customer.id,
      authentication_token: this.userService.getActiveToken(),
    });
  }
}
