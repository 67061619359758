var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collections-list__wrapper"},[_c('div',{staticClass:"collections-list"},[_c('v-row',{attrs:{"align":"baseline","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('v-text-field',{staticClass:"ma-2",attrs:{"append-icon":"mdi-magnify","label":"Search by Name or Code","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('v-row',{attrs:{"align":"center","justify":"end"}})],1)],1),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading.table),expression:"loading.table"}],attrs:{"type":"table","width":"100%","height":"20rem"}}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading.table),expression:"!loading.table"}],ref:"table",attrs:{"single-select":false,"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":_vm.perPage,"page":_vm.page,"server-items-length":_vm.totalRecords,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 25, 50],
      },"item-key":"id","disable-sort":"","show-expand":""},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
      var record = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(record.created_at, true)))])]}},{key:"item.updated_at",fn:function(ref){
      var record = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(record.updated_at, true)))])]}},{key:"item.actions",fn:function(ref){
      var collection = ref.item;
return [_c('table-row-actions',{attrs:{"record":collection,"view":_vm.viewCollection,"edit":_vm.editCollection,"destroy":_vm.destroyCollection,"can-read":_vm.canRead,"can-update":_vm.canUpdate,"can-destroy":_vm.canDestroy}},[(!collection.blacklist_parent_collection_id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","text":"","fab":"","small":""},on:{"click":function($event){return _vm.createBacklistCollection(collection)}}},on),[_c('v-icon',[_vm._v("mdi-alert-plus-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Create Blacklist Collection")])]):_vm._e()],1)]}},{key:"item.data-table-expand",fn:function(ref){
      var collection = ref.item;
      var isExpanded = ref.isExpanded;
      var expand = ref.expand;
return [(collection.blacklist_collections.length > 0)?[(isExpanded)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return expand(false)}}},on),[_vm._v(" mdi-chevron-up ")])]}}],null,true)},[_c('span',[_vm._v("Hide Blacklists")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":expand}},on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('span',[_vm._v("Show Blacklists")])])]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var collection = ref.item;
return [(collection.blacklist_collections.length > 0)?[_c('td',{attrs:{"colspan":headers.length + 1}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","lg":"8","md":"10","sm":"12"}},[_c('v-list',{attrs:{"dense":"","outlined":""}},[_c('v-subheader',{staticClass:"pl-6"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-alert-outline ")]),_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Blacklists")])],1),_c('v-divider'),_vm._l((collection.blacklist_collections),function(blacklist_collection,i){return [_c('v-hover',{key:blacklist_collection.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-list-item',{key:blacklist_collection.id,class:(hover) ? 'grey lighten-4' : undefined,attrs:{"two-line":""}},[_c('v-list-item-avatar',{attrs:{"left":""}},[_c('v-avatar',{attrs:{"color":"grey lighten-4"}},[_vm._v(" "+_vm._s(i + 1)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(blacklist_collection.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(blacklist_collection.meta.description)+" ")])],1),_c('v-list-item-action',{staticClass:"expanded-row-list-item-actions"},[_c('table-row-actions',{attrs:{"record":blacklist_collection,"view":_vm.viewCollection,"edit":_vm.editCollection,"destroy":_vm.destroyCollection,"can-read":_vm.canRead,"can-update":_vm.canUpdate,"can-destroy":_vm.canDestroy}})],1)],1)]}}],null,true)})]})],2)],1)],1)],1)],1)]:_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }