


































import { Vue, Component, Prop } from 'vue-property-decorator';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { Asset, AssetType } from '@/models/internal';

/**
 * Validator for Assets passed in as prop (i.e. correct type, etc.)
 */
const validator = (asset: Asset) => (
  (asset === null) || (asset !== null && asset.type.toLocaleLowerCase() === AssetType.Image)
);

@Component({
  name: 'ImageThumb',
  components: {
    CoolLightBox,
  },
})
export class ImageThumb extends Vue {
  @Prop({ required: true, validator })
  protected readonly asset!: Asset | null;

  @Prop({ required: false, default: 'thumb' })
  protected readonly thumbKey!: string;

  @Prop({ required: false, default: false })
  protected readonly useLightbox!: boolean;

  protected index: number | null = null;

  protected get media() {
    if (!this.asset) return null;

    return {
      /**
       * Title of the image
       */
      title: this.asset.meta?.title,
      /**
       * Image description (caption)
       */
      description: this.asset.meta?.description,
      /**
       * URL of the image
       */
      src: this.asset.largestImage(),
      /**
       * Thumbnail image URL
       */
      thumb: this.asset.versions[this.thumbKey] ?? this.asset.smallestImage(),
    };
  }

  protected get large() {
    if (this.media?.src) return this.media?.src;
    return null;
  }

  protected get small() {
    if (this.media?.thumb) return this.media?.thumb;
    return null;
  }
}

export default ImageThumb;
