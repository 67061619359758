













import { ICreateFormView } from '@/lib/interfaces';
import { Collection } from '@/models/internal';
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { CollectionForm } from '@/models/forms/CollectionForm';
import CollectionsForm from './components/CollectionsForm.vue';
import { CollectionService, UserService } from '@/services';
import { Sync } from 'vuex-pathify';
import { Logger } from '@/tools/Logger';

@Component({
  name: 'CollectionsCreate',
  components: {
    CollectionsForm,
  },
})
export default class CollectionsCreate extends Vue implements ICreateFormView<Collection> {
  @Ref('collection-form')
  public readonly formRef: any;

  @Prop({ required: false })
  public readonly blacklistParentCollection!: Collection;

  @Sync('context/overlay@visible')
  protected isOverlayVisible!: boolean;

  public form!: CollectionForm;

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CollectionsCreate' });

  public created() {
    const collection = new Collection();
    this.form = new CollectionForm(collection);
  }

  public get schema() {
    return this.form.getSchema();
  }

  public get model() {
    return this.form.getModel();
  }

  public set model(model) {
    this.form.setModel(model);
  }

  public mounted() {
    this.formRef.resetValidation();
  }

  public async save(model: Collection): Promise<any> {
    this.isOverlayVisible = true;

    const params = {
      authentication_token: this.userService.getActiveToken(),
      collection: {
        name: model.name,
        code: model.code,
        meta: model.meta,
      },
    };

    try {
      if (this.blacklistParentCollection) {
        await this.collectionService.api.createBlacklist({
          id: this.blacklistParentCollection.id,
          ...params,
        });
      } else {
        await this.collectionService.api.create(params);
      }
      this.$genify.notify('Collection created!', 'success');
      this.$router.push({ name: 'collections-list' });
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert('There was an error creating the Collection.', 'error');
    } finally {
      this.isOverlayVisible = false;
    }
  }
}
