













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ApprovalStatus } from '@/lib/enum';
import { Utility } from '@/tools/Utility';

@Component({
  name: 'ApprovalStatusIndicator',
})
export default class ApprovalStatusIndicator extends Vue {
  /**
   * Status used to render status indicator
   */
  @Prop({ required: true, default: ApprovalStatus.Pending })
  protected readonly status!: ApprovalStatus;

  @Prop({ required: false, default: '' })
  protected readonly label!: string;

  /**
   * Status used to render status indicator
   */
  @Prop({ required: false, default: false })
  private readonly empty!: boolean;

  /**
   * Indicator-text for an Order without items in a particular
   * section
   */
  protected readonly emptyStatus = 'N/A';

  /**
   * Get class-name of the status indicator based on status
   */
  protected getClassObject(status: ApprovalStatus) {
    if (this.empty) return { 'grey--text text--lighten-2': true };
    return {
      'text--darken-2': true,
      'yellow--text': status === ApprovalStatus.Pending,
      'cyan--text': status === ApprovalStatus.Reviewed,
      'green--text': !status || status === ApprovalStatus.Approved,
      'red--text': status === ApprovalStatus.Denied,
    };
  }

  /**
   * Get the icon related to the Order's status
   */
  protected getIcon(status: ApprovalStatus) {
    if (this.empty) return 'mdi-circle-off-outline';
    if (!status) return 'mdi-check-circle';
    switch (status) {
      case ApprovalStatus.Pending:
        return 'mdi-alpha-p-circle';
      case ApprovalStatus.Reviewed:
        return 'mdi-alpha-r-circle';
      case ApprovalStatus.Approved:
        return 'mdi-check-circle';
      case ApprovalStatus.Denied:
        return 'mdi-close-circle';
      default:
        return 'mdi-checkbox-blank-circle';
    }
  }

  /**
   * Format status label for the status indicator's tooltip
   */
  protected formatStatus(status: string) {
    if (!status) {
      return Utility.titleCase(ApprovalStatus.Approved);
    }
    return Utility.titleCase(status);
  }
}
