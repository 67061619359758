












































































import { LazyPromise } from '@/lib/LazyPromise';
import type { VAutoCompleteItem } from '@/lib/types/Vuetify.type';
import { BaseModel } from '@/models/internal';
import { InstanceOf } from '@vuex-orm/core';
import { CreateElement } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

type ModelWithId = BaseModel & { id: number };

@Component({
  name: 'BatchProcessDialog',
})
export default class BatchProcessDialog<S extends ModelWithId, T extends ModelWithId> extends Vue {
  /**
   * Label used to describe the Source entity type
   */
  @Prop({ required: false, default: 'Source(s)' })
  protected readonly sourceType!: string;

  /**
   * Label used to describe the Target entity type
   */
  @Prop({ required: false, default: 'Target(s)' })
  protected readonly targetType!: string;

  /**
   * Promise for resolving a value through asynchronously
   */
  @Prop({ required: true })
  private readonly promise!: LazyPromise<BatchProcessSelection>;

  /**
   * Collection of source entities
   */
  @Prop({ required: false, default: () => [] })
  private readonly sources!: S[];

  /**
   * Collection of target entities
   */
  @Prop({ required: false, default: () => [] })
  private readonly targets!: T[];

  /**
   * Autocomplete text to display for each target entity
   */
  @Prop({ required: false, default: 'name' })
  private readonly targetText!: string;

  /**
   * Autocomplete value to use for each target entity
   */
  @Prop({ required: false, default: 'id' })
  private readonly targetValue!: string;

  protected dialog = true;

  private selected: number[] = [];

  /**
   * Items for displaying in the AutoComplete component
   */
  protected get autoCompleteItems(): VAutoCompleteItem[] {
    return this.targets.map((target: any) => ({
      text: target[this.targetText],
      value: target[this.targetValue],
    }));
  }

  public created() {
    if (this.targets.length === 1) this.selectFirst();
  }

  /**
   * Confirm callback (resolve the LazyPromise)
   */
  public confirm() {
    this.dialog = false;
    this.promise.resolve({
      sources: this.sources.map((source) => source.id),
      targets: this.selected,
    });
    this.$destroy();
  }

  /**
   * Cancel callback (reject the LazyPromise)
   */
  public cancel() {
    this.dialog = false;
    this.promise.reject();
    this.$destroy();
  }

  /**
   * Remove an item from the autocomplete
   */
  protected removeItem (item: VAutoCompleteItem) {
    const index = this.selected.findIndex(o => o === item.value);
    if (index >= 0) this.selected.splice(index, 1);
  }

  /**
   * Select the first - and only the first - item from list of targets
   */
  private selectFirst() {
    this.selected = [(this.targets[0] as any)[this.targetValue]];
  }
}

/**
 * Helper function for showing a BatchProcessDialog modal
 * programmatically.
 */
export async function renderBatchDialog<S extends BaseModel = InstanceOf<BaseModel>, T extends BaseModel = any>(
  this: any,
  propsData: IBatchProcessDialogProps<S, T>,
): Promise<BatchProcessSelection> {
  const lazyPromise = new LazyPromise();
  const { title, subtitle } = propsData;
  const instance = new BatchProcessDialog({
    parent: this,
    propsData: {
      ...propsData,
      promise: lazyPromise,
    },
    render: function(createElement: CreateElement) {
      const options: any = {
        props: {
          promise: lazyPromise,
          sources: propsData.sources,
          targets: propsData.targets,
          sourceType: propsData.sourceType,
          targetType: propsData.targetType,
          targetText: propsData.targetText,
          targetValue: propsData.targetValue,
        },
      };
      if (title || subtitle) {
        options.scopedSlots = {};
        if (title) {
          options.scopedSlots.title = function createTitle() {
            return createElement('span', title);
          };
        }
        if (subtitle) {
          options.scopedSlots.subtitle = function createSubtitle() {
            return createElement('span', subtitle);
          };
        }
      }
      return createElement('batch-process-dialog', options);
    },
  });
  instance.$mount();
  return await lazyPromise;
}

export interface IBatchProcessDialogProps<S extends BaseModel, T extends BaseModel> {
  /**
   * Title to show at top of Dialog modal
   */
  title: string;
  /**
   * Subtitle to show underneath the title at top of Dialog modal
   */
  subtitle: string;
  /**
   * Source entities
   */
  sources: S[];
  /**
   * Target entities
   */
  targets: T[];
  /**
   * Type of the source entities (optional)
   */
  sourceType?: string;
  /**
   * Type of the target entities (optional)
   */
  targetType?: string;
  /**
   * Property of target's text in autocomplete (defaults to `name)
   */
  targetText?: string;
  /**
   * Property of target's value in autocomplete (defaults to `id`)
   */
  targetValue?: string;
}

/**
 * Type returned from LazyPromise once confirmed and resolved.
 */
export type BatchProcessSelection = { sources: number[]; targets: number[] };
