























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CustomerService, UserService } from '../../services';
import { Logger } from '@/tools/Logger';
import { debounce } from 'lodash';

/**
 * For selecting sites without a Dist Channel
 */
const NONE_LABEL = '(None)';

@Component({
  name: 'DistChannelAutocomplete',
})
export default class DistChannelAutocomplete extends Vue {
  @Prop({ required: false, default: undefined })
  protected name?: string;

  @Prop({ required: false, default: false })
  protected dense!: boolean;

  @Prop({ required: false, default: false })
  protected outlined!: boolean;

  @Prop({ required: false, default: false })
  protected chips!: boolean;

  @Prop({ required: true })
  protected label!: string;

  @Prop({ required: false, default: () => [] })
  protected value!: string[];

  /**
   * Attributes to be bound to component in template
   */
  @Prop({ required: false, default: () => ({}) })
  protected options!: Record<string, any>;

  @Prop({ required: false, default: true })
  private showNoneLabel!: boolean;

  @Prop({ required: false, default: true })
  private debouncedChange!: boolean;

  @Prop({ required: false, default: false })
  private multiple!: boolean;

  protected loading = false;

  private readonly logger: Logger = new Logger({ context: 'DistChannelAutocomplete' });

  private readonly customerService = CustomerService.getInstance();

  private readonly userService = UserService.getInstance();

  private selected: string | string[] = '';

  private data: string[] = [];

  private debouncedEmitChange = debounce(this.emitChange, 500);

  @Watch('value')
  protected watchValue(value: string[]) {
    this.selected = value;
  }

  public created() {
    if (this.multiple) this.selected = [];
    this.init();
  }

  /**
   * Change event listener
   */
  protected change() {
    return (this.debouncedChange) ? this.debouncedEmitChange(): this.emitChange();
  }

  private async init() {
    this.loading = true;
    try {
      const distChannels = await this.fetchData();
      this.data = distChannels;

      if (this.showNoneLabel) {
        this.data.unshift(NONE_LABEL);
      }
    } catch (error) {
      this.logger.error(error);
      return [];
    } finally {
      this.loading = false;
    }
  }

  /**
   * Fetch the distribution channels
   */
  private async fetchData(): Promise<string[]> {
    return this.customerService.api.distributionChannels({
      authentication_token: this.userService.getActiveToken(),
    });
  }

  /**
   * Emit event for changed data
   */
  private emitChange() {
    this.$emit('input', this.selected);
  }
}
