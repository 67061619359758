








import { Vue, Component } from 'vue-property-decorator';
import { Page } from '@/components/Page.vue';

@Component({
  name: 'Collections',
  components: {
    Page,
  },
})
export default class Collections extends Vue {
}
