















































































































































































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { Customer } from '@/models/internal';
import { DataTableHeader } from 'vuetify';
import { Utility } from '@/tools/Utility';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import ListSelectAllAlert from '@/components/Alert/ListSelectAllAlert.vue';
import CustomersListDetails from './CustomersListDetails.vue';
import type { PaginationOptions } from '@/lib/interfaces';

const defaultHeaders: DataTableHeader[] = [
  { text: 'Name', value: 'name', width: 180 },
  { text: 'Phone', value: 'phone', width: 200, sortable: false },
  { text: 'Contact', value: 'contact' },
  { text: 'Address', value: 'address' },
  { text: 'City', value: 'city' },
  { text: 'On Hold', value: 'on_credit_hold', width: 100, sortable: false },
  { text: 'Auto-Approval', value: 'auto_approval_status', width: 100, sortable: false },
  // { text: 'Status', value: 'approval_status', width: 100 },
  { text: 'Actions', value: 'actions', width: 200, sortable: false, filterable: false },
];

@Component({
  name: 'CustomersDataTable',
  components: {
    TableRowActions,
    ApprovalStatusIndicator,
    ListSelectAllAlert,
    CustomersListDetails,
  },
})
export default class CustomersDataTable extends Vue {
  @Prop({ required: false, default: () => defaultHeaders })
  protected readonly headers!: DataTableHeader[];

  @Prop({ required: false, default: () => [] })
  protected readonly customers!: Customer[];

  @Prop({ required: true })
  protected totalRecords!: number | string;

  @Prop({ required: false, default: '' })
  protected search!: string;

  @Prop({ required: false, default: false })
  protected showExpand!: boolean;

  @PropSync('selected', { required: false, default: () => [] })
  protected readonly selectedCustomers!: Customer[];

  @PropSync('options', { required: true })
  protected readonly syncedOptions!: PaginationOptions;

  @PropSync('allItemsSelected', { required: false, default: false })
  protected syncedAllItemsSelected!: boolean;

  protected highlightSearchText = Utility.highlightSearchText;

  protected everyItemToggle = false;

  /**
   * Workaround to access v-data-header every-item prop
   * (Could not find a way to sync header-props)
   */
  protected setEveryItemToggle(everyItem: boolean) {
    this.everyItemToggle = everyItem;
    if (!everyItem) {
      this.syncedAllItemsSelected = false;
    }
  }

  /**
   * Format the phone number
   */
  protected formatPhone(phone: string) {
    return Utility.formatPhone(phone);
  }

  protected enableOnCreditHold(customer: Customer) {
    this.$emit('update-on-credit-hold', true, customer);
  }

  protected disableOnCreditHold(customer: Customer) {
    this.$emit('update-on-credit-hold', false, customer);
  }

  protected getAutoApprovalStatus(customer: Customer) {
    return customer.meta?.auto_approval?.enabled;
  }

  protected getAutoApprovalMode(customer: Customer) {
    return customer.meta?.auto_approval?.mode;
  }

  protected getAutoApprovalIcon(customer: Customer) {
    const approvalMode = this.getAutoApprovalMode(customer);
    switch (approvalMode) {
      case 'all':
        return 'mdi-check';
      case 'custom-all':
        return 'mdi-check-circle-outline';
      case 'custom-sections':
        return 'mdi-checkbox-multiple-marked-circle-outline';
      default:
        throw new Error(`Invalid approval mode: ${approvalMode}`);
    }
  }

  protected getAutoApprovalModeText(customer: Customer) {
    const approvalMode = this.getAutoApprovalMode(customer);
    switch (approvalMode) {
      case 'all':
        return 'All';
      case 'custom-all':
        return 'Custom - Approve ALL sections';
      case 'custom-sections':
        return 'Custom - Approve ANY sections';
      default:
        throw new Error(`Invalid approval mode: ${approvalMode}`);
    }
  }
}
