
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ImageThumb } from '@/components/ImageThumb.vue';
import { Asset, AssetType, Product } from '@/models/internal';
import { AssetService, UserService } from '@/services';
import { IModelView } from '@/lib/interfaces';
import { Maybe } from '@/lib/types';
import { ProductsImageUploader } from './ProductsImageUploader.vue';

@Component({
  name: 'ProductsImageThumb',
  components: {
    ImageThumb,
    ProductsImageUploader,
  },
})
export class ProductsImageThumb extends Vue implements IModelView<Asset> {
  @Prop({ required: false, default: false })
  protected readonly useUpload!: boolean;

  @Prop({ required: false, default: false })
  protected readonly useLightbox!: boolean;

  @Prop({ required: true })
  private product!: Product;

  public data: Maybe<Asset> = null;

  private assetService = AssetService.getInstance();

  public mounted() {
    this.init();
  }

  public async fetchData(id: number): Promise<any> {
    return this.assetService.api.find({
      type: 'image',
      authentication_token: UserService.getInstance().getActiveToken(),
      entity: 'products',
      entity_id: id,
    });
  }

  public async init(): Promise<void> {
    const { assets } = await this.fetchData(this.product.id);
    this.product.assets = assets.map((asset: any) => new Asset(asset));
    this.data = this.product.latestImageAsset();
  }

  /**
   * Callback for the `image-update` event, which occurs when the uploader component
   * successfully uploads a new asset to the server
   */
  protected onImageUpdate(asset: Asset): void {
    this.setProductImage(asset);
    this.$genify.alert('Successfully updated Product\'s image!', 'success');
  }

  /**
   * Use Assets to set the Product's image URL
   */
  private setProductImage(asset: Asset | null): void {
    if (!asset && (this.product.image_url_small || this.product.image_url)) {
      this.data = new Asset({
        url: String(this.product.image_url_small ?? this.product.image_url),
        type: AssetType.Image,
      });
    }

    this.data = asset ?? null;
  }
}

export default ProductsImageThumb;
