







































import { Collection, Customer, prepareData } from '@/models/internal';
import { CollectionService, CustomerService, UserService } from '@/services';
import { DataTableHeader } from 'vuetify';
import { IFindOneArguments, ITableView, PaginationOptions } from '@/lib/interfaces';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { renderBatchDialog } from '@/components/Dialog/BatchProcessDialog.vue';
import CustomersDataTable from '@/views/Dashboard/Customers/components/CustomersDataTable.vue';
import CustomersListControls from '@/views/Dashboard/Customers/components/CustomersListControls.vue';
import { debounce } from 'lodash';
import { Logger } from '@/tools/Logger';
import TableRowActions from '@/components/Table/TableRowActions.vue';

@Component({
  name: 'CollectionsCustomersList',
  components: {
    CustomersDataTable,
    CustomersListControls,
    TableRowActions,
  },
})
export default class CollectionsCustomersList extends Vue implements ITableView<Customer> {
  @Prop({ required: true })
  private readonly collection!: Collection;

  public data: Customer[] = [];

  public selected: Customer[] = [];

  public headers: DataTableHeader[] = [
    { text: 'Name', value: 'name', width: 180, sortable: false, filterable: false },
    { text: 'Phone', value: 'phone', width: 200, sortable: false, filterable: false },
    { text: 'Contact', value: 'contact', sortable: false, filterable: false },
    { text: 'Address', value: 'address', sortable: false, filterable: false },
    { text: 'City', value: 'city', sortable: false, filterable: false },
    { text: 'Actions', value: 'actions', width: 120, sortable: false, filterable: false },
  ];

  protected readonly actions = [
    {
      label: 'Remove from Collection',
      value: 'batch_disable_customers',
      icon: 'mdi-minus-box-multiple',
    },
  ];

  protected options: PaginationOptions = {
    page: 1,
    itemsPerPage: 5,
    sortBy: ['name'],
    sortDesc: [false],
  };

  protected loading = false;

  private readonly collectionService: CollectionService = CollectionService.getInstance();

  private readonly customerService: CustomerService = CustomerService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CollectionsCustomersList' });

  private readonly getBatchSelection = renderBatchDialog.bind(this);

  private readonly debouncedInit = debounce(this.init, 500);

  private totalRecords = Infinity;

  private search = '';

  private allItemsSelected = false;

  @Watch('search')
  protected watchCustomersSearch() {
    this.loading = true;
    this.selected = [];
    this.options.page = 1;
    this.debouncedInit();
  }

  @Watch('options', { deep: true, immediate: false })
  protected watchPagination() {
    this.loading = true;
    this.init();
  }

  public created() {
    this.init();
  }

  /**
   * Initialize data for Customers enabled for this Collection
   */
  public async init() {
    this.loading = true;
    try {
      const { customers, total } = await this.fetchData();
      this.data = customers;
      this.totalRecords = total;
    } catch (error) {
      this.logger.error(error);
    }
    this.loading = false;
  }

  /**
   * Fetch a list of Customers enabled for this Collection
   */
  public async fetchData() {
    this.loading = true;

    try {
      const params: IFindOneArguments = {
        id: this.collection.id,
        authentication_token: this.userService.getActiveToken(),
        page: this.options.page,
        per_page: this.options.itemsPerPage,
      };
      if (this.search) {
        params.name_containing = this.search;
      }
      // If the pagination has the `All` flag, delete pagination params
      if (this.options.itemsPerPage === -1) {
        params.page = 1;
        params.per_page = this.totalRecords;
      }
      const { customers, total } = await this.collectionService.api.findCustomers(params);

      const preparedData = prepareData(customers, CustomerService.mapData);
      await this.customerService.insert({ data: preparedData });

      return {
        customers: this.customerService.findIn(customers.map((data: any) => data.id)),
        total,
      };
    } catch (error) {
      this.logger.error(error);

      return {
        customers: [],
        total: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  /**
   * Listener for actions committed via list's mutli-select
   */
  protected async onAction(action: string) {
    try {
      switch (action) {
        case 'batch_enable_customers': {
          return this.enableMany(this.selected);
        }
        case 'batch_disable_customers': {
          return this.disableMany(this.selected);
        }
        default:
          break;
      }
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * Remove a Customer from a Collection
   */
  protected async disableOne(customer: Customer) {
    this.loading = true;

    try {
      await this.collectionService.api.disableCustomer({
        id: this.collection.id,
        customer_id: customer.id,
        authentication_token: this.userService.getActiveToken(),
      });

      this.selected = this.selected.filter((o) => o.id !== customer.id);
      this.init();
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * User can read a Collection and Customer record
   */
  protected canRead() {
    return this.$ability.can('read', Collection) && this.$ability.can('read', Customer);
  }

  /**
   * User can manage a Collection and Customer record
   */
  protected canManage() {
    return this.$ability.can('manage', Collection) && this.$ability.can('manage', Customer);
  }

  /**
   * Disable one or more Customers for this Collection
   */
  private async disableMany(customers: Customer[]) {
    const { sources, targets } = await this.getBatchSelection<Customer, Collection>({
      title: 'Remove Customers from Collections',
      subtitle: 'Select one or more Collections to remove your selected Customers from',
      sources: customers,
      targets: [this.collection],
      sourceType: 'Customers',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });

    this.loading = true;

    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchDisableCustomers(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            name_containing: this.search,
          },
        );
      } else {
        result = await this.collectionService
          .batchDisableCustomers(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      this.init();
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }

  /**
   * Enable one or more Customers for this Collection
   */
  private async enableMany(customers: Customer[]) {
    const { sources, targets } = await this.getBatchSelection<Customer, Collection>({
      sources: customers,
      targets: [this.collection],
      title: 'Enable Customers for Collections',
      subtitle: 'Select one or more Collections to enable your selected Customers for',
      sourceType: 'Customers',
      targetType: 'Collections',
      targetText: 'name',
      targetValue: 'id',
    });

    this.loading = true;

    try {
      let result;

      if (this.allItemsSelected) {
        result = await this.collectionService.batchEnableCustomers(
          sources,
          targets,
          {
            select_all: this.allItemsSelected,
            name_containing: this.search,
          },
        );
      } else {
        result = await this.collectionService.batchEnableCustomers(sources, targets);
      }

      this.$genify.notify('Saved!', 'success');
      this.init();
      return result;
    } catch (error) {
      this.$genify.alert('Error! Unable to save changes', 'error');
      this.logger.error(error);
    } finally {
      this.selected = [];
    }
  }
}
